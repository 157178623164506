import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _95310ffc = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _40ca1bcc = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _2ccaf0bd = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _3d19ac40 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _0d8ab382 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _4c90861e = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _09704e40 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _129b0d5e = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _718fb8fd = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _4ac975d2 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _70dec362 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _ea418b42 = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _e42e633a = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _4c6e903a = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _45e4a4be = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _f6645570 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _0f084c3e = () => interopDefault(import('../src/pages/select-extras/product/index.vue' /* webpackChunkName: "pages/select-extras/product/index" */))
const _1c528bc8 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _91ade0ce = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _95310ffc,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _40ca1bcc,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _2ccaf0bd,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _3d19ac40,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _0d8ab382,
    name: "receipt"
  }, {
    path: "/search",
    component: _4c90861e,
    name: "search"
  }, {
    path: "/search-booking",
    component: _09704e40,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _129b0d5e,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _718fb8fd,
    name: "terms-and-conditions"
  }, {
    path: "/unsupported-browser",
    component: _4ac975d2,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _70dec362,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _ea418b42,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _e42e633a,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _4c6e903a,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _45e4a4be,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _f6645570,
    name: "select-extras-lounges"
  }, {
    path: "/select-extras/product",
    component: _0f084c3e,
    name: "select-extras-product"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _1c528bc8,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _91ade0ce,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
